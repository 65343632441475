import styled from 'styled-components';

const InfiniteQuiz = () => {
  return (
    <InfiniteQuizWrapper>
      <h1>InfiniteQuiz</h1>
    </InfiniteQuizWrapper>
  )
};

const InfiniteQuizWrapper = styled.div``;

export default InfiniteQuiz;